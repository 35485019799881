import computeDistanceBetween from 'geolib/es/getDistance';

const getFirebaseAnalyticsData = (driver, category, pageTitle) => {
  return {
    category,
    driver_id: driver?.id,
    page_title: pageTitle,
    relation: `${driver?.companyType}:${driver?.companyRelation}`
  };
};

export default getFirebaseAnalyticsData;

/**
 * 1000 = 1km
 */
export const DESTINATION = {
  AREA_RADIUS: 1000
};

/**
 *
 * @param {GeolocationCoordinates} driverPosition Must be an object with Lat and Lng only.
 * @param {GeolocationCoordinates} destinationPosition Package destination. Must be an object with Lat and Lng only.
 * @returns
 */
export function isDistanceAboveAllowed(driverPosition, destinationPosition) {
  const distance = computeDistanceBetween(driverPosition, destinationPosition);
  return distance > DESTINATION.AREA_RADIUS;
}

/**
 *
 * @param {GeolocationCoordinates} driverPosition Must be an object with Lat and Lng only.
 * @param {GeolocationCoordinates} destinationPosition Package destination. Must be an object with Lat and Lng only.
 * @returns
 */
export function shouldNotifyDriverAboutDistance(
  driverPosition,
  destinationPosition
) {
  return isDistanceAboveAllowed(driverPosition, destinationPosition);
}
